import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // AOS CSS
import '../styles/ServiceSection.css';
import ContactModal from './ContactModal'; // Import the modal component

const services = [
    { id: 1, title: 'Web Development', description: 'Custom websites designed and developed to meet your business needs.' },
    { id: 2, title: 'Website Management', description: 'Comprehensive maintenance and management for your online presence.' },
    { id: 3, title: 'Digital Media', description: 'Innovative strategies to strengthen your digital media presence.' },
    { id: 4, title: 'Domains and Servers', description: 'Reliable domain and hosting solutions for smooth online operations.' },
    { id: 5, title: 'E-commerce Solution', description: 'Robust e-commerce platforms to skyrocket your online sales.' },
    { id: 6, title: 'Web Application Development', description: 'Advanced web applications to automate and streamline your processes.' },
];


function ServicesSection() {
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentService, setCurrentService] = useState({});

    useEffect(() => {
        // Initialize AOS
        AOS.init({
            duration: 1000,
            once: true,
            delay: 200,
        });
    }, []);

    const handleOpenModal = (service) => {
        setCurrentService(service);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <section className="services-section">
            <h2 className="services-title">Our Services</h2>
            <div className="services-list">
                {services.map((service, index) => (
                    <div 
                      key={service.id} 
                      className="service-item"
                      data-aos="fade-up" 
                      data-aos-delay={`${index * 100}`}
                    >
                        <h3>{service.title}</h3>
                        <p>{service.description}</p>
                        <button onClick={() => handleOpenModal(service)}>Contact Us</button>
                    </div>
                ))}
            </div>
            <ContactModal 
              isOpen={isModalOpen} 
              onClose={handleCloseModal} 
              serviceTitle={currentService.title}
            />
        </section>
    );
}

export default ServicesSection;
