import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection'; // Make sure the path is correct
import ServicesSection from './components/ServiceSection';
import OurWorkSection from './components/OurWorkSection';
import TechStackSection from './components/TechStackSection';
import OurClientsSection from './components/OurClientsSection';
import NewletterSection from './components/NewletterSection';
import Footer from './components/Footer';

import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Grow4Tech - Software Development & Social Media Services</title>
        <meta name="description" content="Grow4Tech offers premium software development and social media services across India, with a base in Mangalore. Contact us for innovative and tailored solutions." />
        <meta name="keywords" content="software development, social media services, Mangalore, India, software solutions, social media marketing" />
        <meta property="og:title" content="Grow4Tech - Software Development & Social Media Services" />
        <meta property="og:description" content="Grow4Tech offers premium software development and social media services across India, with a base in Mangalore. Contact us for innovative and tailored solutions." />
        <meta property="og:type" content="website" />
        {/* Add more meta tags as needed */}
      </Helmet>
      <Navbar />
      <HeroSection /> {/* Include the HeroSection component here */}
      <ServicesSection />
      <TechStackSection />
      <OurWorkSection />
      <OurClientsSection />
      <NewletterSection />
      <Footer />
      
      {/* Rest of your app content */}
    </div>
  );
}

export default App;
