import React from 'react';
import '../styles/TechStackSection.css'; // Ensure you have this CSS file in your project

// Import your tech stack logos
import logoReact from '../assets/logo-react.png';
import logoNode from '../assets/logo-nodejs.png';
import logoJava from '../assets/logo-java.png';
import logoHTML5 from '../assets/logo-html5.png';
import logoCSS from '../assets/logo-css.png';
import logoExpress from '../assets/logo-express.png';
import logoAndroid from '../assets/logo-android.png';
import logoApple from '../assets/logo-apple.png';
import logoBootstrap from '../assets/logo-bootstrap.png';
import logoFigma from '../assets/logo-figma.png';
import logoJavascript from '../assets/logo-javascript.png';
import logoMongoDB from '../assets/logo-mongodb.png';
import logoMySQL from '../assets/logo-mysql.png';
import logoShopify from '../assets/logo-shopify.png';
import logoSpringBoot from '../assets/logo-sprintboot.png';
import logoWebflow from '../assets/logo-webflow.png';
import logoWooCommerce from '../assets/logo-woocommerce.png';
import logoWordpress from '../assets/logo-wordpress.png';


//... import other logos

const techStacks = [
  { id: 1, name: 'React', logo: logoReact },
  { id: 2, name: 'Node.js', logo: logoNode },
  { id: 3, name: 'Java', logo: logoJava },
  { id: 4, name: 'HTML 5', logo: logoHTML5 },
  { id: 5, name: 'CSS', logo: logoCSS },
  { id: 6, name: 'Express.js', logo: logoExpress },
  { id: 7, name: 'Android', logo: logoAndroid },
  { id: 8, name: 'Apple', logo: logoApple },
  { id: 9, name: 'Bootstrap', logo: logoBootstrap },
  { id: 10, name: 'Figma', logo: logoFigma },
  { id: 11, name: 'Javascroript', logo: logoJavascript },
  { id: 12, name: 'MongoDB', logo: logoMongoDB },
  { id: 13, name: 'MySQL', logo: logoMySQL },
  { id: 14, name: 'Spring Boot', logo: logoSpringBoot },
  { id: 15, name: 'Webflow', logo: logoWebflow},
  { id: 16, name: 'WooCommerce', logo: logoWooCommerce },
  { id: 17, name: 'WordPress', logo: logoWordpress },
  { id: 18, name: 'Shipify', logo: logoShopify },
  
  //... add other tech stacks
];

const TechStackSection = () => {
  return (
    <section className="tech-stack-section">
      <h2>Our Expertise</h2>
      <div className="tech-stack-container">
        {techStacks.map(stack => (
          <div key={stack.id} className="tech-stack-item" data-aos="fade-up">
            <img src={stack.logo} alt={stack.name} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default TechStackSection;
