import React, { useState } from 'react';
import '../styles/Navbar.css';
import logo from "../assets/logo.png";

function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="/">
        <img src={logo} alt="Grow4Tech" />
        </a>
      </div>
      <button className={`hamburger ${isNavExpanded ? "is-active" : ""}`} 
              onClick={() => setIsNavExpanded(!isNavExpanded)}>
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <div className={`navbar-links ${isNavExpanded ? "show" : ""}`}>
        <a href="/" onClick={() => setIsNavExpanded(false)}>HOME</a>
        <a href="/services" onClick={() => setIsNavExpanded(false)}>SERVICES</a>
        <a href="/about" onClick={() => setIsNavExpanded(false)}>ABOUT US</a>
        <a href="/contact" onClick={() => setIsNavExpanded(false)}>CONTACT US</a>
      </div>
      {/* <div className={`navbar-actions ${isNavExpanded ? "hide" : ""}`}>
        <button className="btn-login">LOGIN</button>
        <button className="btn-signup">SIGN UP</button>
      </div> */}
    </nav>
  );
}

export default Navbar;
