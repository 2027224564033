import React from 'react';
import '../styles/OurClientsSection.css'; // Path to your CSS file
import logoVulcans from '../assets/logo-vulcans.png';
import logoGramajanya from '../assets/logo-gramajanya.png';
import logoGreenSpoon from '../assets/logo-Greenspoon.jpg';
// import logoHilightStudio from '../assets/logo-highlight.png';
import logoVNR from '../assets/logo-vnr.jpg';
import logoSwastishree from '../assets/logo-swastishree.png';
import logoAmpacity from '../assets/logo-ampacity.png';

// Dummy data for client logos
const clientLogos = [
    { id: 1, src: logoVulcans, alt: "Vulcans Academy" },
    { id: 2, src: logoGramajanya, alt: "Gramajanya" },
    { id: 3, src: logoGreenSpoon, alt: "Green Spoon" },
    // { id: 4, src: logoHilightStudio, alt: "HiLight Lighting Studio" },
    { id: 4, src: logoVNR, alt: "VNR Group Of Companies" },
    { id: 5, src: logoSwastishree, alt: "Swastishree" },
    { id: 6, src: logoAmpacity, alt: "Ampacity" }
    // Add more client logos as needed
];

const OurClientsSection = () => {
    return (
        <section className="our-clients">
            <div className="container">
                <h2>Our Esteemed Clients</h2>
                <div className="clients-logos">
                    {clientLogos.map(logo => (
                        <div key={logo.id} className="client-logo-container">
                            <img src={logo.src} alt={logo.alt} className="client-logo" />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default OurClientsSection;
