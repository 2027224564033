import React, { useState } from 'react';
import "../styles/ContactModal.css";

const ContactModal = ({ isOpen, onClose, serviceTitle }) => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    emailAddress: '',
    companyName: '',
    message: '',
  });

  const [showToast, setShowToast] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    // Name validation: Only characters A-Z and a-z
    if (!/^[A-Za-z ]+$/.test(formData.name)) {
      alert('Name must contain only letters.');
      return false;
    }

    // Phone number validation: Only numbers 0-9 and not exceed 10 digits
    if (!/^\d{10}$/.test(formData.phoneNumber)) {
      alert('Phone Number must contain only 10 digits.');
      return false;
    }

    // Email address validation: Simple pattern for email validation
    if (!/^\S+@\S+\.\S+$/.test(formData.emailAddress)) {
      alert('Please enter a valid email address.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation checks
    if (!validateForm()) return; // Stop the submission if validation fails

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/submit-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error! status: ${response.status}, body: ${errorText}`);
      }

      setShowToast(true);
      setTimeout(() => setShowToast(false), 5000); // Hide toast after 5 seconds
      onClose(); // Close the modal after successful submission
      setFormData({ // Reset the form state
        name: '',
        phoneNumber: '',
        emailAddress: '',
        companyName: '',
        message: '',
      });
    } catch (error) {
      console.error('Submit error:', error);
      alert('There was a problem submitting your form. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {showToast && <div className="toast-notification">Form submitted successfully!</div>}
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <h2>Contact Us About {serviceTitle}</h2>
          <form onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Name *" required value={formData.name} onChange={handleChange} />
            <input type="tel" name="phoneNumber" placeholder="Phone Number *" required value={formData.phoneNumber} onChange={handleChange} />
            <input type="email" name="emailAddress" placeholder="Email Address *" required value={formData.emailAddress} onChange={handleChange} />
            <input type="text" name="companyName" placeholder="Company Name" value={formData.companyName} onChange={handleChange} />
            <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange}></textarea>
            <button type="submit">Submit</button>
          </form>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </>
  );
};

export default ContactModal;
