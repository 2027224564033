import React, { useState } from 'react';
  import '../styles/OurWorkSection.css';
  import vulcans from '../assets/vulcans.png';
  import solutio from '../assets/solutio.png';
  import swastishree from '../assets/swastishree.png';
  import leftArrow from '../assets/right-arrow.png'; // Adjust according to your assets
  import rightArrow from '../assets/left-arrow.png'; // Adjust according to your assets
  

const works = [
    { id: 'work1', title: 'Solutio', imageUrl: solutio, link: 'https://solutio.grow4tech.com', description: 'Solutio: Simplifying hackathon registration with our Bootstrap-powered platform. Join the innovation journey effortlessly!' },
    { id: 'work2', title: 'Vulcans', imageUrl: vulcans, link: 'https://vulcans.in', description: 'Vulcans: Elevate your IELTS prep with our custom mock tests! Crafted using the versatile MERN stack, our web app offers a seamless experience for mastering English proficiency.' },
    { id: 'work3', title: 'Swastishree', imageUrl: swastishree, link: 'https://lucid-bartik.148-113-9-31.plesk.page', description: 'Swastishree: Revolutionizing mobility with our MERN-built solution. Seamlessly crafted to optimize operations and enhance user experience.' },
    // Add more works with descriptions as needed
  ];
  
  const OurWorkSection = () => {
    const [activeIndex, setActiveIndex] = useState(1); // Active card index
  
    const handlePrevClick = () => {
      setActiveIndex((prevIndex) => (prevIndex - 1 + works.length) % works.length);
    };
  
    const handleNextClick = () => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % works.length);
    };
  
    return (
      <div className="our-work-section">
        <h2>Our Work</h2>
        <button onClick={handlePrevClick} className="arrow left">
          <img src={leftArrow} alt="Previous" />
        </button>
        <div className="cards-container">
          {works.map((work, index) => (
            <div
              key={work.id}
              className={`card ${index === activeIndex ? 'active' : ''}`}
              style={{ backgroundImage: `url(${work.imageUrl})` }}
            >
              <div className="card-content">
                <a
                  href={work.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="card-link"
                >
                  <div className="card-title">{work.title}</div>
                </a>
                <div className="card-description">
                  <p>{work.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button onClick={handleNextClick} className="arrow right">
          <img src={rightArrow} alt="Next" />
        </button>
      </div>
    );
  };
  
  export default OurWorkSection;
  