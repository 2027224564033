import React from 'react';
import '../styles/HeroSection.css'; // Make sure to create this CSS file and use the correct path

function HeroSection() {
    return (
        <section className="hero-section">
            <div className="hero-content">
                <h1>CRAFTING SOLUTIONS EMPOWERING CREATORS</h1>
                <h2>Why Grow4Tech?</h2>
                <p>
                    Welcome to Grow4Tech, where innovation meets creativity. Join us on a journey to transform ideas into reality and empower your creative spirit with cutting-edge technology. Together, let's build solutions that make a difference.
                </p>
                <button className="explore-button">Explore More</button>
            </div>
        </section>
    );
}

export default HeroSection;




