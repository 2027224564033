import React, { useState } from 'react';
import '../styles/NewsletterSection.css'; // Ensure this path is correct

const NewsletterSection = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(''); // Declare state for handling messages
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage button behavior during submission

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button during submission
    setMessage(''); // Reset message state

    try {
      const response = await fetch('https://grow4tech.com/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to subscribe to newsletter');
      }

      const data = await response.json();
      console.log('Successfully subscribed to newsletter:', data);
      setMessage('Thank you for subscribing to our newsletter!'); // Set success message
      setEmail(''); // Clear the email input field after successful submission
    } catch (error) {
      console.error('Error subscribing to newsletter:', error);
      setMessage(error.message || 'An error occurred. Please try again.'); // Set error message
    } finally {
      setIsSubmitting(false); // Re-enable the button after submission
    }
  };

  return (
    <section className="newsletter-section">
      <div className="newsletter-container">
        <h2 className="newsletter-title">Stay Updated</h2>
        <p className="newsletter-subtitle">Sign up for our newsletter to receive the latest news and updates.</p>
        {message && <div className="newsletter-message">{message}</div>} {/* Display feedback message */}
        <form onSubmit={handleSubmit} className="newsletter-form">
          <input
            type="email"
            className="newsletter-input"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="newsletter-button" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'SIGN UP'}
          </button>
        </form>
      </div>
    </section>
  );
};

export default NewsletterSection;
