import React from 'react';
import '../styles/Footer.css'; // Ensure you have this CSS file in the correct path


function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h4>OUR INFO</h4>
                    <ul>
                        <li>About Grow4Tech</li>
                        <li>Learning</li>
                        <li>Investor Relations</li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h4>CONTACT</h4>
                    <p>Phone: +91 79751 11622</p>
                    <p>Address: Kanara Tower, Kotara Chowki, NH-66, Ashoknagar (MR), Mangalore 575006</p>
                </div>

                <div className="footer-section">
                    <h4>LEGAL</h4>
                    <ul>
                        <li>Terms & Condition</li>
                        <li>Privacy Policy</li>
                        <li>Promotional T & C</li>
                    </ul>
                </div>




                {/* <div className="footer-section">
          <h4>DOWNLOAD OUR APP</h4>
          <img src={appStoreBadge} alt="Download on the App Store" />
          <img src={playStoreBadge} alt="Get it on Google Play" />
        </div> */}
            </div>

            <div className="footer-bottom">
                <div className="footer-logo">
                    {/* Place your logo here */}
                </div>
                <p>© 2024 Grow4Tech – All Rights Reserved.</p>
                <div className="footer-social-icons">
                    <a href="https://www.facebook.com/profile.php?id=61551943382783" aria-label="Facebook" target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://twitter.com/GROW4_TECH" aria-label="Twitter" target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://www.instagram.com/grow4tech?igsh=MWV6MjZudjJvamF1cw==" aria-label="Instagram" target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/97403187/admin/feed/posts/?feedType=following" aria-label="LinkedIn" target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                </div>

            </div>
        </footer>
    );
}

export default Footer;
